.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @media screen and (min-width: 350px) and (max-width: 932px) {
  /* Your CSS styles for screens between 350px and 900px wide go here */
  /* .card23{

  } */
  .whatsappicon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    height: 80px;
    z-index: 1000; /* Adjust the z-index to ensure it's above other elements on the page */
    /* Add any other styling properties you want, such as background, padding, etc. */
  }
  
  .founder:hover{
    transform: scale(1.2);
  }

  .founder {
    transition: transform 0.3s ease-in-out;
  }

  @media (max-width: 766px){


    .founder{
margin-left: -10px;
margin-bottom: -60px;
height: 320px;
    }
    .tabing{
      margin-Top:50px
    }
    .tabing1{
      margin-Top:10px
    }

   
  }

  .dropdown-menu{
    --bs-dropdown-link-active-bg :#D66E1E!important;}