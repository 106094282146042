/* //////////////////// ADS SECTION START////////////////// */

.adsection{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    background-color:#3B393D;
    color:white;
    z-index: 99999;
    width: 100%;
  z-index: 9999999999999999999999999999999999999999999999999999;
}
.yourComponentId{
   z-index: 99999999999999999999999999999999999999999;
    width: 100%;
position: fixed;
top: 0px;

}

.navlogo{
  height: 50px;
  }
.nav-link{
  font-weight: 700;
  color: #484B4D;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 

}



/* Navbar.css */
.navbar {
  transition: background-color 0.3s ease;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
z-index: 999999999999999999999999999999999999999;
}




.navbar{
    min-height: 80px !important;
    z-index: 1111;
}
/* //////////////////// ADS SECTION END////////////////// */
@media screen and (min-width: 370px) and (max-width: 932px) {
  /* Your CSS styles for screens between 350px and 900px wide go here */

  .packges{
flex-direction: column;
  }
  .card{
    justify-content: center;
    width:94%;
    margin-top: 20px;
    display: flex;
    margin-left: 0px;
    margin-right: 0px;
  }
.newone{
  justify-content: end !important;
  
}

  .adsection{
    display: flex;
 justify-content: center;

}

.fornumb{
  font-size: 12px;
}
}

.forhover:hover{
  filter: grayscale(1);
  cursor: pointer;
}
.btnprimary{
  border: 1 px solid #c05959;
}

/* Add the following styles for mobile responsiveness */
@media (max-width: 768px) {
 .woowowo{
  margin-bottom: 100px !important;
 }

  .cardnew {
    margin-bottom: 15px; /* Adjust as needed */
  }

  .navbar {
min-height: 50px !important;
padding: 0%;
margin: 0% ;

  }
  

  .card-img-top{
    height: 90px;
    width: 90px;
    margin-bottom:10px;
  }
  .cardheading{
    font-size: 10px;
    margin-bottom: 10px;
  }

  .navlogo{
height: 30px;
margin-top: 10px;
  }

  .dekstopView{
    display: none;
  }
}





@media (max-width: 361px){
  .fornumb{
    font-size: 10px;
  }

}

