/* .card-costom  {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 28rem !important;
    height: 340px !important;
    text-align: center;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin:0 1rem;
  } */

  .cardbody11:hover {
    transform: translateY(-20px);
  }


  /* .cardbody15{
    margin-top: -60px ;
  } */
  
  /* .trendincard {
    max-width: 100%;
    padding: 1rem  !important;
  } */
  
  /* .bodyofcard{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  
  /* .cardhead {
    margin-bottom: 14px;
  } */
  
  /* .custom-btn {
    width: 200%;
    margin-left: -17px;
    margin-top: 7px;
    border: none;
  } */
  


  .whatsapp-btn {
    background-color: #4FC95D;
  }
.read-btn{
  background-color: #414040 !important;
}



  @media (min-width: 280px) and (max-width: 787px) {
    /* .mobile-view {
      display: none; 
    } */

    /* .trendincard1{
      width: 50px;
      padding: 0%;
      margin: 0%;
    } */
    /* .card-costom1{
      height: 400px;
    } */

    /* .custom-btn {
      margin-left: -17px;
      margin-top: 7px;
      border: none;
    } */

    /* .cardbody15{
      margin-top: -230px ;
    
    } */

    /* .cardbody11{
      margin-left: 50px;
    } */
  }
  @media (min-width: 280px) and (max-width: 369px) {
    .cardbody11{
      margin-left: 10px;
      margin-top:20px;
    }
    
    /* .mobile-view {
      display: none;
    } */

    /* .trendincard1{
      width: 50px;
      padding: 0%;
      margin: 0%;
    } */
    /* .card-costom1{
      height: 400px;
    } */

    /* .custom-btn {
      margin-left: -17px;
      margin-top: 7px;
      border: none;
    } */

    /* .cardbody15{
      margin-top: -70px ;
    
    } */


  }
  @media (min-width: 767px) and (max-width: 1400px) {

    /* .trendincard1{
      width: 50px;
      padding: 0%;
      margin: 0%;
    } */
    /* .card-costom1{
      height: 400px;
    } */

    /* .custom-btn {
      margin-left: -17px;
      margin-top: 7px;
      border: none;
    } */

    /* .cardbody15{
      margin-top: -290px ;
    margin-left: 5px !important;
    } */

    /* .cardbody11{
      margin-left: 50px;
    } */
  }

  .cardbody11:hover{
    filter: grayscale(1);
    transition: all 0.8s ease-in-out;
    /* color: white;
    background-color: #44474B */
  }



  @media (max-width: 768px) {
.cardbody11{
  margin-left: 10px;
}


  }

  @media (min-width: 767px) and (max-width: 1400px) {
    .cardbody11{
/* margin: 100px; */
  }

}

.card-title{
  font-weight: bold;
  font-size: 17px !important;
}